import { useTranslation } from "react-i18next";

import styles from "./style.module.scss";
import { useTitle } from "#utils/hooks";

const Homepage = () => {
  const { t } = useTranslation();
  useTitle("common.title.homepage", t);

  return (
    <main className={styles.homepage}>
      <section className={styles.homepage__section}>
        <video src="./images/homepage-loop.mp4" autoPlay loop muted />
        <div className={styles.homepage__texts}>
          <p>Let Gaming Enrich Our Daily Life！</p>
          <p>遊戲成為平台豐富我們食衣住行育樂的日常！</p>
        </div>
      </section>
    </main>
  );
};

export default Homepage;
