import ViewListIcon from "@mui/icons-material/ViewList";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useTitle } from "#utils/hooks";
import { News } from "#utils/types";
import styles from "./style.module.scss";

import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import { markdownBlankLineFormator } from "#utils/helpers";

const NewsDetail = () => {
  const location = useLocation();
  const { t } = useTranslation();
  useTitle("common.title.news", t);
  const i18n = (key: string) => t(`page.news-detail.${key}`);
  const navigate = useNavigate();
  const [news, setNews] = useState<News>();

  useEffect(() => {
    const id = location.pathname.split("/").pop();
    fetch("data/news.json")
      .then((res) => res.json())
      .then(async (res) => {
        const news: News = res.data.find((news: News) => news.id === id);
        let newsMarkdown: string = "";
        if (news.markdown) {
          newsMarkdown = await (
            await fetch(`data/news-md/${news.id}.md`)
          ).text();
          news.content = newsMarkdown;
        }
        if (news) {
          setNews({ ...news });
        } else {
          navigate("/news");
        }
      });
  }, [location.pathname, navigate]);

  if (!news) return <></>;
  return (
    <main className={styles.newsDetail}>
      <section className={styles.newsDetail__section}>
        <Link to={{ pathname: "/news", search: location.search }} className={styles.newsDetail__back}>
          <ViewListIcon className={styles.newsDetail__back__icon} />
          <p>{i18n("toList")}</p>
        </Link>
        <div className={styles.newsDetail__banner}>
          {news.isBannerVideo ? (
            <video src={news.bannerUrl} autoPlay controls muted />
          ) : (
            <img src={news.bannerUrl || news.imgUrl} alt={news.title} />
          )}
        </div>
      </section>
      <section className={styles.newsDetail__section}>
        <h2 className={styles.newsDetail__title}>{news.title}</h2>
        <p className={styles.newsDetail__date}>
          {dayjs(news.date).format("YYYY/MM")}
        </p>
        <article className={styles.newsDetail__content}>
          {news.markdown ? (
            <ReactMarkdown
              className={styles.newsDetail__content__markdown}
              children={markdownBlankLineFormator(news.content)}
              remarkPlugins={[remarkBreaks]}
            />
          ) : (
            news.content.split("\n").map((p) => <p key={p}>{p}</p>)
          )}
        </article>
      </section>
    </main>
  );
};

export default NewsDetail;
