import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";

import styles from "./style.module.scss";
import { useTitle } from "#utils/hooks";

const initialValues = {
  email: "",
  fullname: "",
  country: "",
  company: "",
  subject: "",
  message: "",
};

const Contact = () => {
  const { t, i18n: i18nI } = useTranslation();
  useTitle("common.title.contact-us", t);
  const i18n = (key: string) => t(`page.contact-us.${key}`);
  const validationSchema = yup.object({
    email: yup
      .string()
      .email(i18n("form.email.invalid"))
      .required(i18n("form.email.required")),
    fullname: yup.string().required(i18n("form.fullname.required")),
    country: yup
      .string()
      .required(i18n("form.country.required"))
      .typeError(i18n("form.country.required")),
    company: yup.string().required(i18n("form.company.required")),
    subject: yup.string().required(i18n("form.subject.required")),
    message: yup.string().required(i18n("form.message.required")),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log("values: ", values);
    },
  });

  useEffect(() => {
    formik.validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18nI.language]);

  return (
    <main className={styles.contact}>
      <section className={styles.contact__section}>
        <h2>{i18n("title")}</h2>
        <div className={styles.contact__wrapper}>
          {/* <form className={styles.contact__form} onSubmit={formik.handleSubmit}>
            <ContactFormItem id="email" formik={formik} i18n={i18n}>
              <TextField
                id="email"
                name="email"
                placeholder={i18n("form.email.placeholder")}
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </ContactFormItem>
            <ContactFormItem id="fullname" formik={formik} i18n={i18n}>
              <TextField
                id="fullname"
                name="fullname"
                placeholder={i18n("form.fullname.placeholder")}
                value={formik.values.fullname}
                onChange={formik.handleChange}
              />
            </ContactFormItem>
            <ContactFormItem id="country" formik={formik} i18n={i18n}>
              <Autocomplete
                options={countries}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={i18n("form.country.placeholder")}
                  />
                )}
                noOptionsText={i18n("form.country.invalid")}
                isOptionEqualToValue={(o, v) => o !== "" || o === v}
                value={formik.values.country}
                onChange={(_, v) => formik.setFieldValue("country", v)}
              />
            </ContactFormItem>
            <ContactFormItem id="company" formik={formik} i18n={i18n}>
              <TextField
                id="company"
                name="company"
                placeholder={i18n("form.company.placeholder")}
                value={formik.values.company}
                onChange={formik.handleChange}
              />
            </ContactFormItem>
            <ContactFormItem id="subject" formik={formik} i18n={i18n}>
              <Select
                id="subject"
                name="subject"
                value={formik.values.subject}
                onChange={formik.handleChange}
              >
                <MenuItem value="1">subject 1</MenuItem>
                <MenuItem value="2">subject 2</MenuItem>
                <MenuItem value="3">subject 3</MenuItem>
              </Select>
            </ContactFormItem>
            <ContactFormItem id="message" formik={formik} i18n={i18n}>
              <TextField
                id="message"
                name="message"
                placeholder={i18n("form.message.placeholder")}
                value={formik.values.message}
                onChange={formik.handleChange}
                multiline
                rows={8}
              />
            </ContactFormItem>
            <Button className={styles.contact__form__submit} type="submit">
              {i18n("form.submit")}
            </Button>
          </form> */}
          <ul className={styles.contact__info}>
            <li className={styles.contact__info__item}>
              <h3 className={styles.contact__info__title}>
                {i18n("info.enquiries.title")}
              </h3>
              <p className={styles.contact__info__content}>
                service@fxgames.cloud
              </p>
            </li>
            <li className={styles.contact__info__item}>
              <h3 className={styles.contact__info__title}>
                {i18n("info.careers.title")}
              </h3>
              <p className={styles.contact__info__content}>
                fxgamespersonnel@gmail.com
              </p>
            </li>
            <li className={styles.contact__info__item}>
              <h3 className={styles.contact__info__title}>
                {i18n("info.headquarter.title")}
              </h3>
              <p className={styles.contact__info__content}>
                Taipei, Taiwan 02-2368 2780
              </p>
            </li>
          </ul>
        </div>
      </section>
    </main>
  );
};

export default Contact;
