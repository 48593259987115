import { Navigate } from "react-router-dom";
import BackOffice from "#pages/back-office";
import Contact from "#pages/contact-us";
import Games from "#pages/games";
import GameDetail from "#pages/games/gameDetail";
import History from "#pages/history";
import Homepage from "#pages/homepage";
import News from "#pages/news";
import NewsDetail from "#pages/news/newsDetail";
import PrivacyPolicy from "#pages/privacy-policy";
import AIGC from "#pages/ai-gc";

export interface IRouterType {
  path: string;
  element?: JSX.Element;
  label?: string;
  children?: IRouterType[];
}

/* 若無需顯示在NavBar則 label = undefined 
   若NavBar有多層顯示需求則將route放在children下
*/

export const routes: IRouterType[] = [
  { path: "/", element: <Homepage /> },
  // { path: "/about-us", element: <AboutUs />, label: "about-us" },
  { path: "/games", element: <Games />, label: "games" },
  {
    path: "/games/:gameName",
    element: <GameDetail />,
  },
  {
    path: "/back-office",
    element: <BackOffice />,
    label: "back-office",
  },
  {
    path: "/ai-gc",
    element: <AIGC />,
    label: "ai-gc",
  },
  { path: "/news", element: <News />, label: "news" },
  {
    path: "/news/:newsId",
    element: <NewsDetail />,
  },
  // {
  //   path: "/partners",
  //   element: <Partners />,
  //   label: "partners",
  // },
  { path: "/history", element: <History />, label: "history" },
  {
    path: "/about-terms",
    label: "about-terms",
    children: [
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
        label: "privacy-policy",
      },
    ],
  },
  {
    path: "/contact-us",
    element: <Contact />,
    label: "contact-us",
  },

  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
];
