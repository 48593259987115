import { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { Language as LanguageIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import styles from "./style.module.scss";

const LANGUAGES = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "中文",
    value: "zh-TW",
  },
];

const LanguageToggle = () => {
  const { i18n } = useTranslation();
  const [languageAnchor, setLanguageAnchor] =
    useState<HTMLButtonElement | null>(null);

  return (
    <>
      <IconButton
        onClick={(e) => setLanguageAnchor(e.currentTarget)}
        className={styles.language}
        aria-label="language"
      >
        <LanguageIcon />
      </IconButton>
      <Menu
        anchorEl={languageAnchor}
        open={Boolean(languageAnchor)}
        onClose={() => {
          setLanguageAnchor(null);
        }}
        disableScrollLock
      >
        {LANGUAGES.map((language) => (
          <MenuItem
            key={language.value}
            onClick={() => {
              setLanguageAnchor(null);
              i18n.changeLanguage(language.value);
            }}
          >
            {language.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageToggle;
