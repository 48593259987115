import {
  Facebook as FacebookIcon,
  // Twitter as TwitterIcon,
  YouTube as YouTubeIcon,
  // LinkedIn as LinkedInIcon,
  Instagram as InstagramIcon,
} from "@mui/icons-material";

import styles from "./style.module.scss";

const ICONS = [
  {
    icon: <FacebookIcon style={{ width: "100%", height: "100%" }} />,
    link: "https://www.facebook.com/FantasyXGames/",
    label: "facebook",
  },
  // {
  //   icon: <TwitterIcon style={{ width: "100%", height: "100%" }} />,
  //   link: "https://www.twitter.com",
  //   label: "twitter",
  // },

  {
    icon: <YouTubeIcon style={{ width: "100%", height: "100%" }} />,
    link: "https://www.youtube.com/@fxgcloudYT/",
    label: "youtube",
  },
  // {
  //   icon: <LinkedInIcon style={{ width: "100%", height: "100%" }} />,
  //   link: "https://www.linkedin.com",
  //   label: "linkedin",
  // },
  {
    icon: <InstagramIcon style={{ width: "100%", height: "100%" }} />,
    link: "https://www.instagram.com/fxgames.official/",
    label: "instagram",
  },
];

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <ul className={styles.footer__icons}>
        {ICONS.map((icon) => (
          <li key={icon.link}>
            <a
              href={icon.link}
              target="_blank"
              rel="noreferrer"
              aria-label={icon.label}
            >
              {icon.icon}
            </a>
          </li>
        ))}
      </ul>
      <ul className={styles.footer__texts}>
        <li>
          <p className={styles.footer__texts__title}>Privacy Policy</p>
          <p className={styles.footer__texts__content}>
            Fantasy X Games Co., Ltd. values your privacy. We are committed to
            protect Personal Information entrusted to the company by our
            Customers and Partners. If you have any requests concerning your
            personal information or any queries, please do not hesitate to
            contact us.
          </p>
        </li>
        <li>
          <p className={styles.footer__texts__title}>Cookies</p>
          <p className={styles.footer__texts__content}>
            When you visit this website, we may send one or more cookies - a
            small text file containing a string of alphanumeric characters - to
            your computer that uniquely identifies your browser. A cookie may
            also convey information to us about how you use the website (e.g.,
            the pages you view, the links you click, or your preferred browsing
            language), and allow us to provide a better web browsing experience
            to you over time. The cookies we send do not give us access to your
            computer or allow us to collect or process any personal information.
            You can also learn more about cookies by visiting{" "}
            <a
              href="https://www.allaboutcookies.org"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "underline" }}
            >
              www.allaboutcookies.org
            </a>{" "}
            which includes additional useful information on cookies and how to
            block cookies using different types of browser. Some of our Service
            Providers may use their own cookies in connection with the services
            they perform on our behalf.
          </p>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
