import {
  ExpandLess,
  ExpandMore,
  MailOutline as MailOutlineIcon,
} from "@mui/icons-material";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  Collapse,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import { IRouterType, routes } from "routes";
import LanguageToggle from "./LanguageToggle/LanguageToggle";
import styles from "./style.module.scss";
import { grey } from "@mui/material/colors";
enum MenuStateEnum {
  OPEN = 0,
  CLOSE = 1,
}
const Header = () => {
  const [anchorEls, setAnchorEls] = useState<(HTMLElement | null)[]>(
    routes.map((item) => null)
  );
  const [collapseOpen, setCollapseOpen] = useState<boolean[]>(
    routes.map((item) => false)
  );
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const navRef = useRef<HTMLDivElement>(null);
  const i18n = (key: string) => t(`header.${key}`);
  const [showNav, setShowNav] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleMenuAnchorEls = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
    type: MenuStateEnum
  ) => {
    const newAnchorEls = [...anchorEls];
    switch (type) {
      case MenuStateEnum.OPEN:
        newAnchorEls[index] = event.currentTarget;
        setAnchorEls(newAnchorEls);
        break;
      case MenuStateEnum.CLOSE:
        newAnchorEls[index] = null;
        setAnchorEls(newAnchorEls);
        break;
    }
  };

  const getMobilePageChildren = (
    routes: IRouterType,
    index: number,
    isCurrent: boolean
  ) => {
    return (
      routes.children &&
      routes.children.length > 0 && (
        <li
          key={routes.label}
          onClick={(event: React.MouseEvent<HTMLLIElement>) => {
            event.stopPropagation();
            const newCollapseOpen = [...collapseOpen];
            newCollapseOpen[index] = !collapseOpen[index];

            setCollapseOpen(newCollapseOpen);
          }}
        >
          <Toolbar
            sx={{
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            {i18n(`nav.${routes.label}`)}
            {collapseOpen[index] ? <ExpandLess /> : <ExpandMore />}
          </Toolbar>
          <Collapse in={collapseOpen[index]}>
            <ul>
              {routes.children.map((node) => (
                <li>
                  <Link
                    className={styles.header__nav__link}
                    onClick={(e) => {
                      if (isCurrent) e.preventDefault();
                      setShowNav(false);
                    }}
                    to={{ pathname: `${node.path}`, search: location.search }}
                  >
                    {i18n(`nav.${node.label}`)}
                  </Link>
                </li>
              ))}
            </ul>
          </Collapse>
        </li>
      )
    );
  };

  const getPCPageChildren = (
    routes: IRouterType,
    index: number,
    isCurrent: boolean
  ) => {
    return (
      routes.children &&
      routes.children.length > 0 && (
        <li key={routes.label}>
          <div
            onClick={(event) =>
              handleMenuAnchorEls(event, index, MenuStateEnum.OPEN)
            }
            className={styles.header__nav__link}
            aria-label={routes.label}
          >
            {i18n(`nav.${routes.label}`)}
          </div>
          <Menu
            anchorEl={anchorEls[index]}
            open={Boolean(anchorEls[index])}
            onClose={(event) =>
              handleMenuAnchorEls(
                event as React.MouseEvent<HTMLElement>,
                index,
                MenuStateEnum.CLOSE
              )
            }
            disableScrollLock
            MenuListProps={{
              sx: {
                backgroundColor: grey[800],
              },
            }}
          >
            {routes.children.map((node) => (
              <MenuItem
                key={node.label}
                onClick={(event) => {
                  navigate(`${node.path}`);
                  handleMenuAnchorEls(
                    event as React.MouseEvent<HTMLElement>,
                    index,
                    MenuStateEnum.CLOSE
                  );
                }}
              >
                <div className={styles.header__nav__link__nest}>
                  {i18n(`nav.${node.label}`)}
                </div>
              </MenuItem>
            ))}
          </Menu>
        </li>
      )
    );
  };

  const getListItem = (routes: IRouterType[]) => {
    return routes
      .filter((route) => Boolean(route.label))
      .map((nav, index) => {
        const isContactUs = nav.label === "contact-us";
        const isCurrentOrNested = location.pathname.startsWith(`/${nav}`);
        const isCurrent = location.pathname === `/${nav}`;
        if (nav.children) {
          if (isMobile) return getMobilePageChildren(nav, index, isCurrent);
          else return getPCPageChildren(nav, index, isCurrent);
        } else {
          return (
            <li key={nav.label}>
              <Link
                className={
                  isContactUs
                    ? classNames(
                        styles.header__nav__link,
                        styles["header__nav__link--contact"]
                      )
                    : isCurrentOrNested
                    ? classNames(styles.header__nav__link, styles.current)
                    : styles.header__nav__link
                }
                onClick={(e) => {
                  if (isCurrent) e.preventDefault();
                  setShowNav(false);
                }}
                to={{ pathname: `${nav.path}`, search: location.search }}
              >
                {isContactUs && <MailOutlineIcon />}
                {i18n(`nav.${nav.label}`)}
              </Link>
            </li>
          );
        }
      });
  };

  useEffect(() => {
    const closeNav = (event: MouseEvent) => {
      if (navRef.current && !navRef.current.contains(event.target as Node))
        setShowNav(false);
    };
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992); // 假設小於 768px 的視窗尺寸視為行動裝置
    };
    window.addEventListener("resize", handleResize);
    window.addEventListener("click", closeNav);
    handleResize(); // 初始畫面時先呼叫一次 handleResize

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("click", closeNav);
    };
  }, []);

  return (
    <header className={styles.header}>
      <Link className={styles.header__logo} to={{ pathname: "/", search: location.search }}>
        <h1>Fantasy X Games</h1>
        <img src={`./images/logo.png`} alt={t("common.company.name")} />
      </Link>
      <div
        className={styles.header__toggle}
        onClick={(e) => {
          e.stopPropagation();
          setShowNav((prev) => !prev);
        }}
      >
        <div className={styles.header__toggle__line}></div>
      </div>
      <nav
        className={
          showNav
            ? classNames(styles.header__nav, styles.showNav)
            : styles.header__nav
        }
        ref={navRef}
      >
        <ul>{getListItem(routes)}</ul>
      </nav>
      <LanguageToggle />
    </header>
  );
};

export default Header;
