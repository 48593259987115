import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Button, useMediaQuery } from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";

import styles from "./style.module.scss";
import { useTitle } from "#utils/hooks";
import {
  capitalize,
  gameNameParser,
  getLocale,
  markdownBlankLineFormator,
  numberWithCommas,
} from "#utils/helpers";
import { Game, GameInfo } from "#utils/types";
import Scale from "#components/Scale/Scale";
import { BONUS_IDENTIFIER } from "..";

import ReactMarkdown from "react-markdown";
import RemarkBreaks from "remark-breaks";

const dataMap = (column: string, value: string | number) => {
  switch (column) {
    case "volatility":
      return <Scale number={value as number} />;
    case "hitRate":
      return <Scale number={Math.floor((value as number) * 5)} />;
    case "rtp":
      return `${(value as number) * 100}%`;
    case "reelsRows":
      return value;
    default:
      return numberWithCommas(value);
  }
};

const GameDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n: i18nI } = useTranslation();
  const i18n = (key: string) => t(`page.game-detail.${key}`);
  const isMediaQueryM = useMediaQuery(`(max-width: 768px)`);
  const [game, setGame] = useState<Game>();
  const [gameInfo, setGameInfo] = useState<GameInfo>();
  useTitle(
    game?.gameInfo[getLocale(i18nI.language)].gameName || "common.title.games",
    t
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const gameJson =  params.get("game_setting") || "games";
    const gameName = location.pathname.split("/").pop();

    const isBonus = gameName?.endsWith(BONUS_IDENTIFIER);

    fetch(`data/${gameJson}.json`)
      .then((res) => res.json())
      .then(async (res) => {
        const game: Game = res.data.find((game: Game) =>
          isBonus
            ? gameNameParser(game.gameInfo.en.gameName).concat(
                BONUS_IDENTIFIER
              ) === gameName && game.isBonus
            : gameNameParser(game.gameInfo.en.gameName) === gameName
        );

        for (const [lang, value] of Object.entries(game.gameInfo)) {
          let gamesMarkdown: string = "";
          switch (lang) {
            case "zh-TW":
              if (value.markdown) {
                gamesMarkdown = await (
                  await fetch(`data/games-md/${gameName}/zh-TW.md`)
                ).text();
                game.gameInfo["zh-TW"].gameDesc = gamesMarkdown;
              }
              break;
            case "en":
              if (value.markdown) {
                gamesMarkdown = await (
                  await fetch(`data/games-md/${gameName}/en.md`)
                ).text();
                value.gameDesc = gamesMarkdown;
                game.gameInfo["en"].gameDesc = gamesMarkdown;
              }
              break;
          }
        }

        if (game) {
          setGame({ ...game });
          setGameInfo({ ...game.gameInfo[getLocale(i18nI.language)] });
        } else {
          navigate("/games");
        }
      });
  }, [i18nI.language, location.pathname, navigate]);

  return (
    <main className={styles.gameDetail}>
      <Link className={styles.gameDetail__back} to={{ pathname: "/games", search: location.search }}>
        <ViewListIcon className={styles.gameDetail__back__icon} />
        <p>{i18n("toList")}</p>
      </Link>
      {game && gameInfo && (
        <>
          <section
            className={classNames(
              styles.gameDetail__section,
              styles["gameDetail__section--leftRight"]
            )}
            style={{ minHeight: isMediaQueryM ? "none" : "65vh" }}
          >
            <div className={styles.gameDetail__text}>
              <h2>{gameInfo?.gameName}</h2>
              {gameInfo?.markdown && gameInfo?.gameDesc ? (
                <ReactMarkdown
                  className={styles.gameDetail__text__markdown}
                  children={markdownBlankLineFormator(gameInfo.gameDesc)}
                  remarkPlugins={[RemarkBreaks]}
                />
              ) : (
                <p>{gameInfo.gameDesc}</p>
              )}
              {!game.isComing && (
                <Button className={styles.gameDetail__button}>
                  <a href={game.playUrl} target="_blank" rel="noreferrer">
                    {i18n("play")}
                  </a>
                </Button>
              )}
            </div>
            <div className={styles.gameDetail__image}>
              <img src={`${game.imgUrl}`} alt={gameInfo.gameName} />
            </div>
          </section>
          {Object.keys(game.data).length > 0 && (
            <section className={styles.gameDetail__section}>
              <ul className={styles.gameDetail__data}>
                {Object.entries(game.data).map(([key, value]) => (
                  <li key={key} className={styles.gameDetail__data__item}>
                    <div className={styles.gameDetail__data__value}>
                      {dataMap(key, value as string | number)}
                    </div>
                    <h3 className={styles.gameDetail__data__label}>
                      {i18n(key)}
                    </h3>
                  </li>
                ))}
              </ul>
            </section>
          )}
          <section
            className={classNames(
              styles.gameDetail__section,
              styles["gameDetail__section--leftRight"]
            )}
          >
            <div className={styles.gameDetail__gallery}>
              <img src={`${game.imgUrl}`} alt={gameInfo.gameName} />
            </div>
            <table className={styles.gameDetail__table}>
              <tbody>
                <tr>
                  <td>{i18n("name")}</td>
                  <td>{gameInfo.gameName}</td>
                </tr>
                <tr>
                  <td>{i18n("type")}</td>
                  <td>{capitalize(game.category)}</td>
                </tr>
                {game.specs?.platforms && (
                  <tr>
                    <td>{i18n("platforms")}</td>
                    <td>{game.specs.platforms.join(", ")}</td>
                  </tr>
                )}
                {game.specs?.languages && (
                  <tr>
                    <td>{i18n("languages")}</td>
                    <td>
                      {game.specs.languages
                        .map((l) => i18n(`languages-list.${l}`))
                        .join(i18nI.language.startsWith("zh-") ? "、" : ", ")}
                    </td>
                  </tr>
                )}
                {game.specs?.orientation && (
                  <tr>
                    <td>{i18n("orientation")}</td>
                    <td>
                      {i18n(`orientation-list.${game.specs.orientation}`)}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </section>
        </>
      )}
    </main>
  );
};

export default GameDetail;
