import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Tabs, Tab } from "@mui/material";

import styles from "./style.module.scss";
import { useGetNews, useTitle } from "#utils/hooks";
import NewsCard from "#components/NewsCard/NewsCard";
import { dateSortComparator } from "#utils/helpers";

const TABS = [
  { label: "all" },
  { label: "company" },
  { label: "games" },
] as const;

const News = () => {
  const { t } = useTranslation();
  useTitle("common.title.news", t);
  const i18n = (key: string) => t(`page.news.${key}`);
  const [tab, setTab] = useState(0);
  const { news: newsList } = useGetNews((news) =>
    news.sort((a, b) => dateSortComparator(a.date, b.date))
  );
  const bannerNews = useMemo(() => newsList[0], [newsList]);

  const handleTabChange = (tabValue: number) => {
    setTab(tabValue);
  };

  return (
    <main className={styles.news}>
      {bannerNews && (
        <section className={styles.news__section}>
          <div className={styles.news__banner}>
            {bannerNews.isBannerVideo ? (
              <video src={bannerNews.bannerUrl} autoPlay controls muted />
            ) : (
              <img src={bannerNews.imgUrl} alt={bannerNews.title} />
            )}
            <h2 className={styles.news__banner__title}>{bannerNews.title}</h2>
          </div>
        </section>
      )}
      <section className={styles.news__section}>
        <div className={styles.news__tabs}>
          <Tabs value={tab} onChange={(_, v) => handleTabChange(v)}>
            {TABS.map((tab) => (
              <Tab key={tab.label} label={i18n(`tabs.${tab.label}`)} />
            ))}
          </Tabs>
        </div>
        <ul className={styles.news__list}>
          {newsList
            .filter((news) => {
              const tabLabel = TABS[tab].label;
              switch (tabLabel) {
                case "all":
                  return true;
                default:
                  return news.type === tabLabel;
              }
            })
            .map((news) => (
              <NewsCard key={news.id} news={news} />
            ))}
        </ul>
      </section>
    </main>
  );
};

export default News;
