import { useTranslation } from "react-i18next";
import classNames from "classnames";

import styles from "./style.module.scss";
import { useGetAIGCInfo, useTitle } from "#utils/hooks";
import { AIGCInfo } from "#utils/types";
import { getLocale } from "#utils/helpers";

interface Props {}

interface AIGCItemProps {
  data: AIGCInfo;
}

const AIGCItem: React.FC<AIGCItemProps> = ({
  data: { content, title, mediaSrc },
}) => {
  const { i18n: i18nI, t } = useTranslation();
  const i18n = (key: string) => t(`page.ai-gc.${key}`);
  return (
    <div className={styles.ai_gc__item}>
      <div className={styles["ai_gc__item--left"]}>
        <h2 className={styles.ai_gc__item__title}>{i18n(title)}</h2>
        <p className={styles.ai_gc__item__content}>
          {content[getLocale(i18nI.language)]}
        </p>
      </div>
      <div className={styles.ai_gc__item__media}>
        <video src={mediaSrc} autoPlay loop muted />
      </div>
    </div>
  );
};
const AIGC: React.FC<Props> = () => {
  const { t } = useTranslation();
  useTitle("common.title.ai-gc", t);
  const i18n = (key: string) => t(`page.ai-gc.${key}`);

  const { aigcInfo } = useGetAIGCInfo();

  return (
    <main className={styles.ai_gc}>
      <section className={classNames(styles.ai_gc__section)}>
        {aigcInfo.map((info, index) => (
          <AIGCItem key={index} data={info} />
        ))}
      </section>
    </main>
  );
};

export default AIGC;
