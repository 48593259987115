import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import styles from "./style.module.scss";
import { News } from "#utils/types";

const NewsCard = ({ news }: { news: News }) => {
  const { t } = useTranslation();
  const i18n = (key: string) => t(`page.news.${key}`);
  const location = useLocation();

  return (
    <li>
      <Link to={{ pathname: `./${news.id}`, search: location.search }} className={styles.newsCard}>
        <div className={styles.newsCard__type}>{i18n(`tabs.${news.type}`)}</div>
        <div className={styles.newsCard__img}>
          <img src={news.imgUrl} alt={news.title} />
        </div>
        <div className={styles.newsCard__text}>
          <h3 className={styles.newsCard__text__title}>{news.title}</h3>
          <p className={styles.newsCard__text__date}>
            {dayjs(news.date).format("YYYY/MM")}
          </p>
        </div>
      </Link>
    </li>
  );
};

export default NewsCard;
