import styles from "./style.module.scss";

const ZhPrivacy = () => {
  return (
    <main className={styles.privacy}>
      <section className={styles.privacy__section}>
        <div className={styles.privacy__section__header}>
          <span className={styles.privacy__section__header__title}>
            隱私權政策
          </span>
          <span className={styles.privacy__section__header__date}>
            2022年12月01日 修訂
          </span>
        </div>
        <div className={styles.privacy__section__content}>
          <div>
            歡迎使用遊戲雲端科技股份有限公司（下稱「本公司」、「我們」）提供的相關服務，我們訂定的隱私政策(下稱「本政策」)
            會說明我們如何蒐集、使用您的個人資料，這些關係著您的權益，所以在使用我們的服務前，請仔細閱讀以下內容；如果您是未成年人，請務必經由法定代理人閱讀並同意以下條款後使用我們的服務。
          </div>
          <ol>
            <li className={styles.privacy__section__content__emphasize}>
              適用範圍
              <div className={styles.privacy__section__content__text}>
                本政策適用於您使用我們開發及經營之應用程式及網站時(下稱「本服務」)，所涉及您相關個人資料的蒐集、處理、利用，但不包括透過本服務而連結至第三人經營之應用程式或網站。
              </div>
            </li>
            <li className={styles.privacy__section__content__emphasize}>
              資料蒐集之目的及類別
              <div>
                我們蒐集您個資的目的是為了能夠提供您相關服務，包括行銷業務、客戶管理與服務、網路購物及其他電子商務服務及與調查、統計與研究分析等。在您使用本服務時，我們可能會蒐集您以下資料
                <ol className={styles.privacy__section__content__even_olist}>
                  <li>
                    <div className={styles.privacy__section__content__text}>
                      您提供的特定資訊。包含但不限於國家、居住地區、語言設定、玩家暱稱及客服意見等。
                    </div>
                  </li>
                  <li>
                    <div className={styles.privacy__section__content__text}>
                      與行動裝置及連線環境相關之資訊。我們可能從您的行動裝置蒐集與行動裝置及連線環境相關之資訊，例如產品型號、作業系統、網際網路服務提供者、行動裝置設定、IP位址及其他行動裝置所獨有而可供辨識之資訊。
                    </div>
                  </li>
                  <li>
                    <div className={styles.privacy__section__content__text}>
                      與您的使用及您與其他玩家互動之相關資訊。在您使用本服務時，我們可能會蒐集您建立的資訊如遊戲內容、遊戲歷程、使用紀錄等，包括但不限於訪問統計及瀏覽紀錄遊戲角色、遊戲歷程、購買記錄、取得獎品、與其他玩家之關係及錯誤報告等相關資訊。
                    </div>
                  </li>
                  <li>
                    <div className={styles.privacy__section__content__text}>
                      與您的購買相關之資訊。您付費購買本服務時，我們可能會蒐集購買日期、數量、項目、信用卡號碼等交易資訊。
                    </div>
                  </li>
                  <li>
                    <div className={styles.privacy__section__content__text}>
                      您與第三方服務之相關資訊。在您將本服務與第三方服務連結時，我們可能蒐集您在第三方服務的相關資訊，例如登入認證資料、個人檔案等。
                    </div>
                  </li>
                  <li>
                    <div className={styles.privacy__section__content__text}>
                      我們會記錄您使用本服務的足跡，以提供您更佳的個人化服務，方便您參與個人化的互動活動。例如，我們會與第三方分析提供商（如
                      Google Analytics）合作，運用 cookies
                      或其他類似技術之工具來記錄、存取及蒐集您的線上行為資料，這有助於我們了解您如何使用我們的服務，以確保能提供您更好的服務體驗。
                    </div>
                  </li>
                </ol>
              </div>
            </li>
            <li className={styles.privacy__section__content__emphasize}>
              資料利用之期間、地區、對象及方式
              <div>
                <ol className={styles.privacy__section__content__even_olist}>
                  <li>
                    <div className={styles.privacy__section__content__text}>
                      利用期間：自您開始使用本服務開始，至本服務結束或是您提出停止繼續再利用的請求。
                    </div>
                  </li>
                  <li>
                    <div className={styles.privacy__section__content__text}>
                      利用地區：臺灣地區。
                    </div>
                  </li>
                  <li>
                    <div className={styles.privacy__section__content__text}>
                      利用對象：我們只會在本政策的目的範圍內使用您的個人資料。除非有下列的情形，我們不會將您的個人資料提供給其它第三人：
                      <ol
                        className={styles.privacy__section__content__odd_olist}
                      >
                        <li>
                          <div
                            className={styles.privacy__section__content__text}
                          >
                            取得您的事前同意；
                          </div>
                        </li>
                        <li>
                          <div
                            className={styles.privacy__section__content__text}
                          >
                            法律要求揭露您的特定資訊；
                          </div>
                        </li>
                        <li>
                          <div
                            className={styles.privacy__section__content__text}
                          >
                            為提供本服務而有提供給關係企業或合作的第三方之必要；
                          </div>
                        </li>
                        <li>
                          <div
                            className={styles.privacy__section__content__text}
                          >
                            當您使用本服務時的行為，違反服務條款或可能損害或妨礙本公司與其他使用者權益或導致任何人遭受損害時，揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。
                          </div>
                        </li>
                      </ol>
                    </div>
                  </li>
                  <li>
                    <div className={styles.privacy__section__content__text}>
                      利用方式
                      <ol
                        className={styles.privacy__section__content__odd_olist}
                      >
                        <li>
                          <div
                            className={styles.privacy__section__content__text}
                          >
                            本公司經營的應用程式或網站：我們可能使用您的個人資訊，以提供、維護及改善本服務，並防止非法活動或違反使用者條款之行為發生。
                          </div>
                        </li>
                        <li>
                          <div
                            className={styles.privacy__section__content__text}
                          >
                            客服意見之處理：若您向本公司反應您於使用本服務的問題時，我們可能使用您的個人資料以解決相關問題。
                          </div>
                        </li>
                        <li>
                          <div
                            className={styles.privacy__section__content__text}
                          >
                            建立及提供統計資訊：我們會基於統計及分析目的使用您的個人資訊，資訊將會以去識別化之形式使用，我們會使用這些資訊改善本服務的運作、提升產品與服務之效能，並作為研發新產品與服務的參考。
                          </div>
                        </li>
                        <li>
                          <div
                            className={styles.privacy__section__content__text}
                          >
                            我們對於cookie的利用包括以下幾種類型：
                            <ol
                              className={
                                styles.privacy__section__content__even_olist
                              }
                            >
                              <li>
                                <div
                                  className={
                                    styles.privacy__section__content__text
                                  }
                                >
                                  使用偏好：我們會利用這些cookie來記住您的設置和偏好。
                                </div>
                              </li>
                              <li>
                                <div
                                  className={
                                    styles.privacy__section__content__text
                                  }
                                >
                                  表現：我們使用這些cookie來收集有關您如何與我們的服務互動的資訊，並幫助我們做相關改善。例如，我們可能會使用這些cookie來確認您是否與某個服務進行互動。
                                </div>
                              </li>
                              <li>
                                <div
                                  className={
                                    styles.privacy__section__content__text
                                  }
                                >
                                  安全支付：我們使用這些cookie來幫助識別和預防安全性的風險。
                                </div>
                              </li>
                              <li>
                                <div
                                  className={
                                    styles.privacy__section__content__text
                                  }
                                >
                                  分析：我們使用這些cookie來幫助我們了解和改進我們的服務。例如，我們可能會使用這些cookie來了解哪些功能最受我們的用戶歡迎，以及我們可以在哪些方面進行改進。
                                </div>
                              </li>
                              <li>
                                <div
                                  className={
                                    styles.privacy__section__content__text
                                  }
                                >
                                  廣告：我們和我們的廣告合作夥伴使用這些cookie來投放廣告，使廣告對我們網站的訪問者更相關和更有意義，並跟踪我們的廣告活動在我們的服務和其他網站上的有效性。
                                </div>
                              </li>
                            </ol>
                          </div>
                        </li>
                      </ol>
                    </div>
                  </li>
                </ol>
              </div>
            </li>
            <li className={styles.privacy__section__content__emphasize}>
              您得行使之權利及方式
              <div>
                <ol className={styles.privacy__section__content__even_olist}>
                  <li>
                    <div className={styles.privacy__section__content__text}>
                      您可以得透過本公司客服信箱(service@fxgames.cloud)請求行使以下權利：
                      <ol
                        className={styles.privacy__section__content__odd_olist}
                      >
                        <li>
                          <div
                            className={styles.privacy__section__content__text}
                          >
                            查詢或請求閱覽。
                          </div>
                        </li>
                        <li>
                          <div
                            className={styles.privacy__section__content__text}
                          >
                            請求製給複製本。
                          </div>
                        </li>
                        <li>
                          <div
                            className={styles.privacy__section__content__text}
                          >
                            請求補充或更正。
                          </div>
                        </li>
                        <li>
                          <div
                            className={styles.privacy__section__content__text}
                          >
                            請求停止蒐集、處理或利用。
                          </div>
                        </li>
                        <li>
                          <div
                            className={styles.privacy__section__content__text}
                          >
                            請求刪除。
                          </div>
                        </li>
                      </ol>
                    </div>
                  </li>
                  <li>
                    <div className={styles.privacy__section__content__text}>
                      權利限制：若您的請求不符合申請程序或法律另有規定者，前項所列權利將會受到限制。
                    </div>
                  </li>
                  <li>
                    <div className={styles.privacy__section__content__text}>
                      酌收費用：若您依本條第一項向本公司查詢、請求閱覽或製給複製本，本公司將酌收必要成本費用。
                    </div>
                  </li>
                  <li>
                    <div className={styles.privacy__section__content__text}>
                      權利行使之方式：本條第一項權利之行使，應填具申請文件，並向本公司提出可資確認之身分證明文件。委託他人代為申請者，應出具委任書並提供本人及代理人之身分證明文件。
                    </div>
                  </li>
                </ol>
              </div>
            </li>
            <li className={styles.privacy__section__content__emphasize}>
              您不提供完整之個人資料時可能對您權益造成的影響
              <div className={styles.privacy__section__content__text}>
                若您無法提供完整且確實之個人資料，您可能會無法順利使用本服務，因此建議您提供完整且確實之個人資料，並應隨時更新。若您不願接受
                cookie
                的寫入，您可在瀏覽器功能中設定隱私權等級為高，但可能會導致本服務的部分功能無法正常執行。
              </div>
            </li>
            <li className={styles.privacy__section__content__emphasize}>
              個人資料之安全維護
              <div className={styles.privacy__section__content__text}>
                本服務均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施以保護您的個人資料，如因業務需要有必要委託其他單位提供服務時，我們也會嚴格要求其遵守保密義務，採取必要檢查程序並確實遵守。
              </div>
            </li>
            <li className={styles.privacy__section__content__emphasize}>
              隱私權政策的修改
              <div className={styles.privacy__section__content__text}>
                本政策可能會為了要因應社會環境及法令規章的變更或科技的進步而修正，我們會將相關的修正於本服務內公告，請隨時留意查閱本政策的更新資訊。本政策之修正於公告時生效，當您於本政策修正後繼續使用本服務，將表示您已閱讀、瞭解並同意接受本政策的修正。
              </div>
            </li>
            <li className={styles.privacy__section__content__emphasize}>
              建議
              <div className={styles.privacy__section__content__text}>
                若您對本政策或與個人資料有任何疑問，歡迎寄送電子郵件至本公司客服中心service@fxgames.cloud。
              </div>
            </li>
          </ol>
        </div>
      </section>
    </main>
  );
};

export default ZhPrivacy;
