import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import ZhPrivacy from "./zh-privacy";
import EnPrivacy from "./en-privacy";

const Privacy = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    console.log(i18n.language);
  }, [i18n.language]);

  return <>{i18n.language === "en" ? <EnPrivacy /> : <ZhPrivacy />}</>;
};

export default Privacy;
