import styles from "./style.module.scss";

const EnPrivacy = () => {
  return (
    <main className={styles.privacy}>
      <section className={styles.privacy__section}>
        <div className={styles.privacy__section__header}>
          <span className={styles.privacy__section__header__title}>
            Privacy Policy
          </span>
          <span className={styles.privacy__section__header__date}>
            Last modified Dec 01, 2022.
          </span>
        </div>
        <div className={styles.privacy__section__en_content}>
          <div>
            Welcome to use relative services provided by Fantasy X Games
            Co.,Ltd. (hereinafter referred to as "the company", “we”, "us"),
            this privacy policy (hereinafter referred to as "the policy") will
            describe how we collect and use your personal information, and this
            information related to your rights. Therefore, please read the
            policy before using our services. Please be reminded that if you are
            a minor, your legal guardian shall agree to our policy and user’s
            terms and conditions before your usage of our services.
          </div>
          <ol className={styles.privacy__section__en_content__olist}>
            <li
              className={`${styles.privacy__section__en_content__olist__li} ${styles.emphasize}`}
            >
              Scope of usage:
              <div className={styles.privacy__section__en_content__text}>
                Our policy shall be applied to the collect, process and use of
                your personal information when you use our applications and
                website developed and managed by our company, but shall not be
                applied to any third-party website or webpages which are
                connected through our applications service or website.
              </div>
            </li>
            <li
              className={`${styles.privacy__section__en_content__olist__li} ${styles.emphasize}`}
            >
              Purpose of data collection and classification
              <div>
                To provide you relative services, which includes marketing,
                customer management and service, online shopping and other
                e-commerce services and investigation, statistics and research
                analysis is our purpose to collect your personal information.
                When you use our applications developed and managed by our
                company, we may collect the data from the following categories.
                <ol className={styles.privacy__section__en_content__olist}>
                  <li
                    className={styles.privacy__section__en_content__olist__li}
                  >
                    <span className={styles.privacy__section__en_content__text}>
                      Personal information: When you use our applications
                      developed and managed by our company, we may collect
                      certain data, including but not limited to your country,
                      residing region, language setting, nickname, customer
                      feedback, and etc.
                    </span>
                  </li>
                  <li
                    className={styles.privacy__section__en_content__olist__li}
                  >
                    <span>
                      Information related to mobile device and internet
                      connection: Our company may obtain your information
                      regarding mobile device and Internet activity, such as
                      product model number, operation system, web server,
                      setting of the mobile device, IP address, and other
                      identifiable information specifically in connection with
                      the mobile device.
                    </span>
                  </li>
                  <li
                    className={styles.privacy__section__en_content__olist__li}
                  >
                    <span>
                      Information related to your activities and activities with
                      other players: When you use the applications developed and
                      managed by our company or have interaction with other
                      players in the applications developed and managed by our
                      company, our company may collect the contents of your game
                      profile, any information you have provided and the gaming
                      history and any records related to your usage of the game
                      (including but not limited to surveys and browsing
                      information), including but not limited to your characters
                      in the game, gaming history, purchase records, rewards,
                      interactions with other players, errors reports and etc.
                    </span>
                  </li>
                  <li
                    className={styles.privacy__section__en_content__olist__li}
                  >
                    <span>
                      Information related to your purchase information: When you
                      purchase our paid gaming services through the applications
                      developed and managed by our company, our company may
                      collect the purchasing information related to purchase
                      date, quantity, item name as well as payment information
                      such as credit card number, account number and
                      verification information.
                    </span>
                  </li>
                  <li
                    className={styles.privacy__section__en_content__olist__li}
                  >
                    <span>
                      Information related to third party service: When you
                      connect the applications developed and managed by our
                      company with a third-party service, our company may
                      collect the information related to the account of the
                      third-party service, such as login verification, personal
                      profile and friend relationship.
                    </span>
                  </li>
                  <li
                    className={styles.privacy__section__en_content__olist__li}
                  >
                    <span>
                      We will record your footprint of usage the service to
                      provide personalized services to you and facilitate your
                      participation in personalized interactive activities. For
                      example, we will work with third-party analytics providers
                      (such as Google Analytics) to use cookies or other tools
                      of similar technology to record, access and collect
                      information about your online behavior, which helps us
                      understand how you use our services, to ensure a better
                      service experience for you.
                    </span>
                  </li>
                </ol>
              </div>
            </li>
            <li
              className={`${styles.privacy__section__en_content__olist__li} ${styles.emphasize}`}
            >
              Retention and use of your personal information
              <div>
                <ol className={styles.privacy__section__en_content__olist}>
                  <li
                    className={styles.privacy__section__en_content__olist__li}
                  >
                    <div className={styles.privacy__section__en_content__text}>
                      Periods of time to use your personal information Unless
                      otherwise permitted or required by the laws, your personal
                      information will be used only for the periods of time
                      starting from providing our service with the applications
                      developed and managed by our company until the service is
                      ended or a request of service suspension is received.
                    </div>
                  </li>
                  <li
                    className={styles.privacy__section__en_content__olist__li}
                  >
                    <div>
                      Territory: Your personal information may be used in your
                      country.
                    </div>
                  </li>
                  <li
                    className={styles.privacy__section__en_content__olist__li}
                  >
                    <div>
                      Scope: Unless otherwise specified by the applicable laws
                      and regulations, your personal information will be used to
                      the extent specified in this policy. Our company will not
                      provide your personal information to a third party, except
                      the following conditions:
                      <ol
                        className={styles.privacy__section__en_content__olist}
                      >
                        <li
                          className={
                            styles.privacy__section__en_content__olist__li
                          }
                        >
                          <div>When we have received your consent;</div>
                        </li>
                        <li
                          className={
                            styles.privacy__section__en_content__olist__li
                          }
                        >
                          <div>
                            Certain information required to be disclosed by the
                            laws;
                          </div>
                        </li>
                        <li
                          className={
                            styles.privacy__section__en_content__olist__li
                          }
                        >
                          <div>
                            Necessary to provide the service to an affiliated
                            company or a third party that cooperates with it.
                          </div>
                        </li>
                        <li
                          className={
                            styles.privacy__section__en_content__olist__li
                          }
                        >
                          <div>
                            Disclosure your personal information is necessary if
                            your behavior when using the service violates the
                            terms of service or may damage or impede the rights
                            of the company and other users or cause damage to
                            anyone.
                          </div>
                        </li>
                      </ol>
                    </div>
                  </li>
                  <li
                    className={styles.privacy__section__en_content__olist__li}
                  >
                    <div>
                      Ways of using the information
                      <ol
                        className={styles.privacy__section__en_content__olist}
                      >
                        <li
                          className={
                            styles.privacy__section__en_content__olist__li
                          }
                        >
                          <div>
                            To provide services related to the applications
                            developed and managed by our company. Our company
                            may use your personal information to provide,
                            maintain and improve the service of the applications
                            developed and managed by our company, and prevent or
                            assist the investigation of illegal activities, or
                            acts in breach of the provisions of this Agreement.
                          </div>
                        </li>
                        <li
                          className={
                            styles.privacy__section__en_content__olist__li
                          }
                        >
                          <div>
                            Procedures for handling customer’s feedback: Upon
                            request for resolving any issues related to the use
                            of the applications developed and managed by our
                            company, our company may use your personal
                            information to solve such issues.
                          </div>
                        </li>
                        <li
                          className={
                            styles.privacy__section__en_content__olist__li
                          }
                        >
                          <div>
                            Establish and provide a statistical database: For
                            the purpose of statistical analysis, our company may
                            anonymously provide your personal information that
                            has been de-identified. Our company may use the
                            information to improve the service, operation and
                            promotional activity and advance the quality and
                            usability of the service. Your information may be
                            also sued by our company for providing references
                            for developing future products and services.
                          </div>
                        </li>
                        <li
                          className={
                            styles.privacy__section__en_content__olist__li
                          }
                        >
                          <div>
                            How we use your cookies
                            <ol
                              className={
                                styles.privacy__section__en_content__olist
                              }
                            >
                              <li
                                className={
                                  styles.privacy__section__en_content__olist__li
                                }
                              >
                                <div
                                  className={
                                    styles.privacy__section__content__text
                                  }
                                >
                                  Technical: We use these cookies to remember
                                  your settings and preferences.
                                </div>
                              </li>
                              <li
                                className={
                                  styles.privacy__section__en_content__olist__li
                                }
                              >
                                <div
                                  className={
                                    styles.privacy__section__content__text
                                  }
                                >
                                  Preference: We use these cookies to collect
                                  information about how you interact with our
                                  services and to help us make improvements. For
                                  example, we may use these cookies to remember
                                  your language preferences.
                                </div>
                              </li>
                              <li
                                className={
                                  styles.privacy__section__en_content__olist__li
                                }
                              >
                                <div
                                  className={
                                    styles.privacy__section__content__text
                                  }
                                >
                                  Secure payment: We use these cookies to help
                                  identify and prevent security risks.
                                </div>
                              </li>
                              <li
                                className={
                                  styles.privacy__section__en_content__olist__li
                                }
                              >
                                <div
                                  className={
                                    styles.privacy__section__content__text
                                  }
                                >
                                  Performance: We use these cookies to collect
                                  information about how you interact with our
                                  services and to help us improve them. For
                                  example, we may use these cookies to determine
                                  whether you have interacted with a certain
                                  page.
                                </div>
                              </li>
                              <li
                                className={
                                  styles.privacy__section__en_content__olist__li
                                }
                              >
                                <div
                                  className={
                                    styles.privacy__section__content__text
                                  }
                                >
                                  Analytic: We use these cookies to help us
                                  understand and improve our services. For
                                  example, we may use these cookies to learn
                                  about which features are most popular with our
                                  users and where we can make improvements.
                                </div>
                              </li>
                              <li
                                className={
                                  styles.privacy__section__en_content__olist__li
                                }
                              >
                                <div
                                  className={
                                    styles.privacy__section__content__text
                                  }
                                >
                                  Advertisement: We and our advertising partners
                                  use these cookies to serve advertisements
                                  activities, to make advertisements more
                                  relevant and meaningful to visitors to our
                                  website, and to track the effectiveness of our
                                  advertising campaigns both on our services and
                                  on other
                                </div>
                              </li>
                            </ol>
                          </div>
                        </li>
                      </ol>
                    </div>
                  </li>
                </ol>
              </div>
            </li>
            <li
              className={`${styles.privacy__section__en_content__olist__li} ${styles.emphasize}`}
            >
              Ways to exercise your right
              <div>
                <ol className={styles.privacy__section__en_content__olist}>
                  <li
                    className={styles.privacy__section__en_content__olist__li}
                  >
                    <div>
                      You can request the following rights with personal data
                      through our customer service mailbox
                      (service@fxgames.cloud):
                      <ol
                        className={styles.privacy__section__en_content__olist}
                      >
                        <li
                          className={
                            styles.privacy__section__en_content__olist__li
                          }
                        >
                          <span>Inquire and request for review</span>
                        </li>
                        <li
                          className={
                            styles.privacy__section__en_content__olist__li
                          }
                        >
                          <span>Request for a copy</span>
                        </li>
                        <li
                          className={
                            styles.privacy__section__en_content__olist__li
                          }
                        >
                          <span>Request for supplements and amendments</span>
                        </li>
                        <li
                          className={
                            styles.privacy__section__en_content__olist__li
                          }
                        >
                          <span>
                            Request for stop collecting, processing or using
                            your personal information
                          </span>
                        </li>
                        <li
                          className={
                            styles.privacy__section__en_content__olist__li
                          }
                        >
                          <span>Request for deleting the information</span>
                        </li>
                      </ol>
                    </div>
                  </li>
                  <li
                    className={styles.privacy__section__en_content__olist__li}
                  >
                    <span>
                      Limitation of the right: If your request fails to be in
                      compliance with the procedures for applications or
                      otherwise stipulated by law, the aforementioned right will
                      be limited.
                    </span>
                  </li>
                  <li
                    className={styles.privacy__section__en_content__olist__li}
                  >
                    <span>
                      Fees: Or company will charge a fee for your request for
                      review or obtain a copy of your information.
                    </span>
                  </li>
                  <li
                    className={styles.privacy__section__en_content__olist__li}
                  >
                    <span>
                      Method to exercise the right: To exercise the first rule
                      of this term, one shall fill out the application form with
                      the provable identification document. If someone else is
                      authorized on behalf of the Principal, Letter of
                      Authorization and the identification documents for both
                      the Principal and the agent shall be provided.
                    </span>
                  </li>
                </ol>
              </div>
            </li>
            <li
              className={`${styles.privacy__section__en_content__olist__li} ${styles.emphasize}`}
            >
              Effect on your right if you do not provide your personal
              information or what you provided is not in full
              <ol className={styles.privacy__section__en_content__olist}>
                <li className={styles.privacy__section__en_content__olist__li}>
                  <span className={styles.privacy__section__en_content__text}>
                    If you do not provide true and full personal information,
                    you may not be able to use the applications developed and
                    managed by our company. Therefore, it is recommended that
                    you provide full, true and updated personal information at
                    all times.
                  </span>
                </li>
                <li className={styles.privacy__section__en_content__olist__li}>
                  <span>
                    If you do not want to accept the recording of cookies, you
                    may set the privacy level to high in the browser function,
                    but it may cause some functions of the service display
                    error.
                  </span>
                </li>
              </ol>
            </li>
            <li
              className={`${styles.privacy__section__en_content__olist__li} ${styles.emphasize}`}
            >
              Security of your personal information
              <div>
                The service is equipped with firewalls, anti-virus systems and
                other related information security equipment and necessary
                security protection measures to protect your personal data. If
                it is necessary to entrust other units to provide services due
                to business needs, we will also strictly require them to comply
                with Confidentiality obligations, to take necessary inspection
                procedures and to comply with them.
              </div>
            </li>
            <li
              className={`${styles.privacy__section__en_content__olist__li} ${styles.emphasize}`}
            >
              Changes to the privacy policy
              <div>
                This policy may be revised in response to changes in the social
                environment, laws and regulations, or advances in technology. We
                will announce the relevant revisions in the service. Please keep
                an eye on the updated information on this policy. The changes to
                our policy shall take effect on the date it is notified and if
                you continue to use the applications developed and managed by
                our company, this means you have read, understood and agreed to
                accept the changes we made to our policy.
              </div>
            </li>
            <li
              className={`${styles.privacy__section__en_content__olist__li} ${styles.emphasize}`}
            >
              Opinions and recommendations
              <div>
                If you have any questions about this policy or any related to
                personal data, please send an email to our customer service
                center at mail to:service@fxgames.cloud@fxgames.cloud。
              </div>
            </li>
          </ol>
        </div>
      </section>
    </main>
  );
};

export default EnPrivacy;
