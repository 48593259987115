import { useState, useEffect } from "react";
import { TFunction } from "i18next";

import { AIGCInfo, Game, History, News, Partner } from "./types";
import { useLocation } from "react-router-dom";

type Parser<T> = (data: T[]) => T[];

export const useTitle = (title: string, t: TFunction) => {
  useEffect(() => {
    document.title = `${t(title)} | ${t("common.company.name")}`;
  }, [t, title]);
};

export const useGetGames = (
  parser: Parser<Game> = (games) => games,
  jsonName?: string
) => {
  const [games, setGames] = useState<Game[]>([]);
  const [gameJson, setGameJson] = useState<string>("games");
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setGameJson(params.get("game_setting") || "games");
  }, [location]);

  useEffect(() => {
    fetch(`data/${gameJson}.json`)
      .then((res) => res.json())
      .then((res) => {
        setGames(parser(res.data as Game[]));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameJson]);

  return { games };
};

export const useGetNews = (parser: Parser<News> = (news) => news) => {
  const [news, setNews] = useState<News[]>([]);

  useEffect(() => {
    fetch("data/news.json")
      .then((res) => res.json())
      .then((res) => {
        setNews(parser(res.data as News[]));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { news };
};

export const useGetPartners = (
  parser: Parser<Partner> = (partners) => partners
) => {
  const [partners, setPartners] = useState<Partner[]>([]);

  useEffect(() => {
    fetch("data/partners.json")
      .then((res) => res.json())
      .then((res) => {
        setPartners(parser(res.data as Partner[]));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { partners };
};

export const useGetHistory = (
  parser: Parser<History> = (history) => history
) => {
  const [history, setHistory] = useState<History[]>([]);

  useEffect(() => {
    fetch("data/history.json")
      .then((res) => res.json())
      .then((res) => {
        setHistory(parser(res.data as History[]));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { history };
};
export const useGetAIGCInfo = (parser: Parser<AIGCInfo> = (info) => info) => {
  const [aigcInfo, setAigcInfo] = useState<AIGCInfo[]>([]);

  useEffect(() => {
    fetch("data/ai-gc.json")
      .then((res) => res.json())
      .then((res) => {
        setAigcInfo(parser(res.data as AIGCInfo[]));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { aigcInfo };
};
