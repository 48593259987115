import { useTranslation } from "react-i18next";
import classNames from "classnames";

import styles from "./style.module.scss";
import { useTitle } from "#utils/hooks";

const BackOffice = () => {
  const { t } = useTranslation();
  useTitle("common.title.back-office", t);
  const i18n = (key: string) => t(`page.back-office.${key}`);

  return (
    <main className={styles.backOffice}>
      <section
        className={classNames(
          styles.backOffice__section,
          styles["backOffice__section--leftRight"]
        )}
      >
        <div className={styles.backOffice__text}>
          <h2>{i18n("gameCMS.title")}</h2>
          <p>{i18n("gameCMS.content")}</p>
        </div>
        <div className={styles.backOffice__image}>
          <video src="images/back-office/CMS.mp4" autoPlay controls muted />
        </div>
      </section>
    </main>
  );
};

export default BackOffice;
