import { useMemo, Fragment, ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";

import styles from "./style.module.scss";
import { History as HistoryType } from "#utils/types";
import { useGetHistory, useTitle } from "#utils/hooks";
import { dateSortComparator } from "#utils/helpers";

type HistoryListMap = { [year: number]: HistoryType[] };

const Wrapper = ({
  children,
  mapNews,
  location,
}: {
  children: ReactNode;
  mapNews?: HistoryType["mapNews"];
  location: ReturnType<typeof useLocation>;
}) => {
  return mapNews ? (
    <Link
      className={styles.history__timeline__content}
      to={{ pathname: `/news/${mapNews.id}`, search: location.search }}
    >
      {children}
    </Link>
  ) : (
    <div className={styles.history__timeline__content}>{children}</div>
  );
};

const History = () => {
  const { t } = useTranslation();
  useTitle("common.title.history", t);
  const i18n = (key: string) => t(`page.history.${key}`);
  const isMediaQueryM = useMediaQuery(`(max-width: 768px)`);
  const { history } = useGetHistory();
  const location = useLocation();
  const historyListMap = useMemo(
    () =>
      history.reduce((prev, curr) => {
        const year = dayjs(curr.date).year();
        if (prev[year]) {
          prev[year] = [...prev[year], curr].sort(
            (a: HistoryType, b: HistoryType) =>
              dateSortComparator(a.date, b.date)
          );
        } else {
          prev[year] = [curr];
        }
        return prev;
      }, {} as HistoryListMap),
    [history]
  );

  return (
    <main className={styles.history}>
      <section className={styles.history__section}>
        <h2 className={styles.history__title}>{i18n("title")}</h2>
        {/* <p className={styles.history__content}>
          公司歷程內文公司歷程內文公司歷程內文公司歷程內文公司歷程內文公司歷程內文
        </p> */}
        <div className={styles.history__timeline}>
          <Timeline position={isMediaQueryM ? "right" : "alternate"}>
            {historyListMap &&
              Object.entries(historyListMap)
                .sort(([year], [year2]) => Number(year2) - Number(year))
                .map(([year, list]) => (
                  <Fragment key={year}>
                    <TimelineItem className="timeline-year-label">
                      <TimelineSeparator className="timeline-year-label">
                        <TimelineDot variant="outlined" />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent className="timeline-year-label">
                        <span>{year}</span>
                      </TimelineContent>
                      <TimelineContent />
                    </TimelineItem>
                    <TimelineItem style={{ height: 0, minHeight: 0 }} />
                    {list.map((history) => (
                      <TimelineItem key={history.title + history.date}>
                        <TimelineSeparator>
                          <TimelineDot variant="outlined" />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Wrapper mapNews={history?.mapNews} location={location}>
                            <div className={styles.history__timeline__header}>
                              <h3 className={styles.history__timeline__title}>
                                {history.title}
                              </h3>
                              <p>{dayjs(history.date).format("YYYY/MM")}</p>
                            </div>
                            {history.imgUrl && (
                              <div className={styles.history__timeline__image}>
                                <img src={history.imgUrl} alt={history.title} />
                              </div>
                            )}
                            <div
                              className={styles.history__timeline__description}
                            >
                              {history.description}
                            </div>
                          </Wrapper>
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </Fragment>
                ))}
          </Timeline>
        </div>
      </section>
    </main>
  );
};

export default History;
